import { type StressTestScenario } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const deleteStressTestScenarioById: ApiFactory<
    ApiEndpoint<StressTestScenario, void, { id: string }>
> = (client) => async ({ params }) => {
    const res = await client.delete<StressTestScenario>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/scenario/${params.id}`,
    );

    return res.data;
};
