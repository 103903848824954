/* eslint-disable react/prop-types */
import { ActionIcon, Stack, Title, Text } from '@mantine/core';
import { type FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Download, Remove } from '@modules/InvestorProAdminPages/shared/Icon';
import { Table } from '@modules/InvestorProAdminPages/shared/Table';
import { ConfirmationModal } from '@modules/InvestorProAdminPages/shared/ConfirmationModal';

import { Pagination } from '../../shared/Table/Pagination';
import { type Report } from '../../entities';
import { useApi } from '../../api/core/ApiProvider';

const columnHelper = createColumnHelper<Report>();

export const ReportsPage: FC = () => {
    const queryClient = useQueryClient();
    const api = useApi();
    const [page, setPage] = useState(1);
    const [report, setReport] = useState<Report | null>(null);
    const [reportIdForDownloadLink, setReportIdForDownloadLink] = useState<string | null>(null);

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: ['report', 'list', page],
        queryFn: async ({ signal }) => await api.reports.getReportsList({
                signal,
                params: {
                    page,
                    size: 20,
                },
            }),
    });

    const {
        data: reportDownloadLink,
        isLoading: isReportLinkLoading,
        isSuccess: isReportLinkSuccess,
    } = useQuery({
        queryKey: ['report', 'downloadLink', reportIdForDownloadLink],
        queryFn: async ({ signal }) => await api.reports.getReportDownloadLink({ signal, params: { id: reportIdForDownloadLink } }),
        enabled: Boolean(reportIdForDownloadLink),
    });

    useEffect(() => {
        if (isReportLinkSuccess && !isReportLinkLoading) {
            const link = document.createElement('a');

            link.href = reportDownloadLink.downloadUrl;
            link.setAttribute('download', 'full-report.pdf');
            link.setAttribute('target', '_blank');
            link.click();
            link.parentNode?.removeChild(link);
            setReportIdForDownloadLink(null);
        }
    }, [isReportLinkSuccess, isReportLinkLoading, reportDownloadLink]);

    const removeReportMutation = useMutation({
        mutationFn: async (id: string) => await api.reports.deleteReportById({ params: { id } }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['report', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: () => handleDeleteModalCLose(),
    });

    const handleDeleteModalCLose = () => {
        setReport(null);
    };

    const handleDeleteConfirm = () => {
        if (report) {
            removeReportMutation.mutate(report.id);
        }
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: 'Название',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('time', {
                header: 'Дата и время создания',
                enableSorting: false,
                size: 1000,
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start' }}>
                        {dayjs(props?.getValue()).format('DD.MM.YYYY')}
                        {' / '}
                        {dayjs(props?.getValue()).format('HH:mm:ss')}
                    </Text>
                ),
            }),

            columnHelper.accessor('id', {
                id: 'download',
                header: '',
                enableSorting: false,
                size: 1,
                meta: {
                    padding: '12px 8px',
                },
                cell: ({ cell }) => (
                    <ActionIcon
                        variant="transparent"
                        size="sm"
                        onClick={() => setReportIdForDownloadLink(cell.getValue())}
                    >
                        <Download width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
            columnHelper.accessor('id', {
                id: 'remove',
                header: '',
                enableSorting: false,
                size: 1,
                meta: {
                    padding: '12px 8px',
                },
                cell: ({ row }) => (
                    <ActionIcon variant="transparent" size="sm" onClick={() => setReport(row.original)}>
                        <Remove width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
        ],
        [],
    );

    return (
        <Stack w={'100dvw'} px={'30px'} pb={'30px'}>
            <Stack>
                <Title order={3}>{'Список отчетов'}</Title>
                <Table
                    columns={columns}
                    data={data?.content || []}
                    highlightOnHover={true}
                    isDataLoading={isLoading || isRefetching}
                    striped={true}
                    footer={<Pagination onChange={setPage} total={data?.totalPages ?? 0} value={page} />}
                />
            </Stack>
            <ConfirmationModal
                onClose={handleDeleteModalCLose}
                onConfirm={handleDeleteConfirm}
                opened={!!report}
                title="Удаление отчета"
                isLoading={removeReportMutation.isPending}
                message={
                    <Text>
                        {'Вы действительно хотите удалить отчет '}
                        <b>{report?.name}</b>
                        {'?'}
                    </Text>
                }
            />
        </Stack>
    );
};
