import { Checkbox, LoadingOverlay, Table as MantineTable, ScrollArea, Stack } from '@mantine/core';
import {
    type CellContext,
    type ColumnDef,
    type HeaderContext,
    type OnChangeFn,
    type RowSelectionState,
    type SortingState,
    type TableState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { type ReactNode, useCallback, useMemo } from 'react';

import { TableHeaderRow } from './TableHeaderRow';
import { TableRow } from './TableRow';

enum TableInternalColumnsIds {
    Selection = 'selection',
}

enum TableSortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
    Default = '',
}

export type TableSort = {
    direction: TableSortDirection;
    field: string;
};

type Props<TData extends Record<string, unknown>> = {
    columns: Array<ColumnDef<TData, any>>;
    data: TData[];
    onRowSelectionChange?: OnChangeFn<RowSelectionState>;
    onSortingChange?: OnChangeFn<SortingState>;
    rowSelection?: RowSelectionState;
    sorting?: SortingState;
    isDataLoading?: boolean;
    header?: ReactNode;
    footer?: ReactNode;
    striped?: boolean;
    highlightOnHover?: boolean;
    className?: string;
    emptyStub?: ReactNode;
};

export const Table = <TData extends Record<string, unknown>>({
    columns,
    data,
    onRowSelectionChange,
    onSortingChange,
    rowSelection,
    sorting,
    isDataLoading,
    header,
    footer,
    striped,
    highlightOnHover,
    className,
    emptyStub,
}: Props<TData>) => {
    const configureColumns = useCallback(() => {
        if (rowSelection && onRowSelectionChange) {
            return [
                {
                    id: TableInternalColumnsIds.Selection,
                    header: ({
                        table: { getIsAllRowsSelected, getIsSomeRowsSelected, getToggleAllRowsSelectedHandler },
                    }: HeaderContext<any, any>) => (
                        <Checkbox
                            checked={getIsAllRowsSelected()}
                            indeterminate={getIsSomeRowsSelected()}
                            onChange={getToggleAllRowsSelectedHandler()}
                        />
                    ),
                    size: 40,
                    cell: (cell: CellContext<any, any>) => (
                        <Checkbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()} />
                    ),
                },
                ...columns,
            ];
        }

        return columns;
    }, [rowSelection, onRowSelectionChange, columns]);

    const state = useMemo<Partial<TableState>>(
        () => ({
            sorting,
            rowSelection,
        }),
        [sorting, rowSelection],
    );

    const { getHeaderGroups, getRowModel } = useReactTable({
        data,
        columns: configureColumns(),
        manualSorting: true,
        manualPagination: true,
        enableMultiRowSelection: true,
        manualFiltering: true,
        getRowId: ({ id }, index) => String(id || index),
        state,
        onSortingChange,
        onRowSelectionChange,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        sortDescFirst: false,
    });

    return (
        <Stack className={className} gap={0}>
            {header}

            <ScrollArea style={{ overflow: 'auto', position: 'relative', minHeight: isDataLoading ? 200 : undefined }}>
                <LoadingOverlay visible={Boolean(isDataLoading)} />
                <MantineTable
                    striped={striped}
                    verticalSpacing="9px"
                    withRowBorders={true}
                    withColumnBorders={true}
                    highlightOnHover={highlightOnHover}
                >
                    <MantineTable.Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <TableHeaderRow headerGroup={headerGroup} key={headerGroup.id} />
                        ))}
                    </MantineTable.Thead>
                    <MantineTable.Tbody>
                        {getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                row={row}
                                selected={Object.keys(rowSelection || {}).includes(row.id)}
                            />
                        ))}
                    </MantineTable.Tbody>
                </MantineTable>
            </ScrollArea>

            {!isDataLoading && getRowModel().rows.length === 0 ? emptyStub : null}

            {footer}
        </Stack>
    );
};
