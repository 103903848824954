import { Button, Group, InputWrapper, LoadingOverlay, Modal, Stack, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { type FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { Calendar } from '@modules/InvestorProAdminPages/shared/Icon/icons/Calendar';
import { type InferParams } from '@modules/InvestorProAdminPages/shared/router';

import { type StressTestScenarioFormValues, stressTestScenarioForm } from './StressTestScenarioForm.schema';

export const StressTestScenarioEditModal: FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id: stressTestScenarioId = '' } = useParams<InferParams<'/stressTestScenarios/:id'>>();
    const handleClose = () => navigate('/stress-test-scenarios');
    const form = useForm<StressTestScenarioFormValues>({
        initialValues: {
            ...stressTestScenarioForm.getDefault(),
            fromDate: new Date(),
            toDate: new Date(),
        },
        validate: yupResolver(stressTestScenarioForm),
    });

    const {
        data: StressTestScenario,
        isSuccess,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ['getStressTestScenarioById', stressTestScenarioId],
        queryFn: async () => await api.stressTestScenarios.getStressTestScenarioById({ params: { id: stressTestScenarioId } }),
        enabled: !!stressTestScenarioId,
        select: (data) => ({ ...data, fromDate: new Date(data.fromDate), toDate: new Date(data.toDate) }),
    });

    useEffect(() => {
        if (isSuccess) form.initialize(StressTestScenario);
    }, [isSuccess, form, StressTestScenario]);

    const { mutate, isPending } = useMutation({
        mutationFn: async ({ fromDate, toDate, ...rest }: StressTestScenarioFormValues) => await api.stressTestScenarios.editStressTestScenario({
                params: { id: stressTestScenarioId },
                data: {
                    ...rest,
                    fromDate: dayjs(fromDate).utc(true).format(),
                    toDate: dayjs(toDate).utc(true).format(),
                },
            }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['stressTestScenarios', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: handleClose,
    });

    const handleSubmit = (values: StressTestScenarioFormValues) => {
        mutate(values);
    };

    return (
        <Modal.Root opened={true} onClose={handleClose} centered={true}>
            <Modal.Overlay blur={2} />
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title fz={22} fw={700}>
                        {'Редактирование сценария cтресс-теста'}
                    </Modal.Title>
                    <Modal.CloseButton />
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay
                        visible={isPending || isLoading || isFetching}
                        overlayProps={{ blur: 2 }}
                        zIndex={1000}
                    />
                    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
                        <Stack gap={0}>
                            <InputWrapper mih="85px">
                                <TextInput
                                    {...form.getInputProps('name')}
                                    required={true}
                                    label="Название"
                                    placeholder="Введите название"
                                />
                            </InputWrapper>
                            <InputWrapper mih="85px">
                                <TextInput
                                    {...form.getInputProps('description')}
                                    required={true}
                                    label="Короткое описание"
                                    placeholder="Введите короткое описание"
                                />
                            </InputWrapper>
                            <Group wrap="nowrap">
                                <InputWrapper mih="85px" w="50%">
                                    <DateInput
                                        {...form.getInputProps('fromDate')}
                                        withAsterisk={true}
                                        radius={6}
                                        rightSection={<Calendar color="#388AF7" width={24} height={24} />}
                                        rightSectionPointerEvents="none"
                                        valueFormat="DD.MM.YYYY"
                                        placeholder="Начало периода"
                                        maxDate={dayjs(form.values.toDate).subtract(1, 'day').toDate()}
                                        label="Начало периода"
                                        styles={{
                                            input: { padding: '6px 18px 6px 12px' },
                                            section: { paddingRight: '12px', width: 'fit-content' },
                                        }}
                                    />
                                </InputWrapper>
                                <InputWrapper mih="85px" w="50%">
                                    <DateInput
                                        {...form.getInputProps('toDate')}
                                        clearable={true}
                                        withAsterisk={true}
                                        radius={6}
                                        rightSection={<Calendar color="#388AF7" width={24} height={24} />}
                                        rightSectionPointerEvents="none"
                                        valueFormat="DD.MM.YYYY"
                                        placeholder="Конец периода"
                                        minDate={dayjs(form.values.fromDate).add(1, 'day').toDate()}
                                        label="Конец периода"
                                        styles={{
                                            input: { padding: '6px 18px 6px 12px' },
                                            section: { paddingRight: '12px', width: 'fit-content' },
                                        }}
                                    />
                                </InputWrapper>
                            </Group>

                            <Group grow={true}>
                                <Button variant="outline" onClick={handleClose}>
                                    {'Отменить'}
                                </Button>
                                <Button type="submit">{'Сохранить'}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
