import { type StressTestScenario, type StressTestScenarioRequestData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const createStressTestScenario: ApiFactory<
    ApiEndpoint<StressTestScenario, StressTestScenarioRequestData>
> = (client) => async ({ data }) => {
    const res = await client.post<StressTestScenario>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/scenario',
        data,
    );

    return res.data;
};
