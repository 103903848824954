import { Table } from '@mantine/core';
import { type Cell, flexRender } from '@tanstack/react-table';

type Props<T extends Record<string, unknown>> = {
    cell: Cell<T, unknown>;
};

export const TableCell = <TableData extends Record<string, unknown>>({
    cell: { row, column, getContext },
}: Props<TableData>) => {
    const styles = column.columnDef.meta?.getStyles?.(row);
    const padding = column.columnDef.meta?.padding ?? '13px 18px';

    return (
        <Table.Td p={padding} w={column.getSize()} style={styles}>
            {flexRender(column.columnDef.cell, getContext())}
        </Table.Td>
    );
};
