import { type BenchmarkRequestData, type BenchmarkResponseData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const editBenchmark: ApiFactory<
    ApiEndpoint<BenchmarkResponseData, BenchmarkRequestData, { id: string }>
> = (client) => async ({ data, params }) => {
    const res = await client.put<BenchmarkResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v2/benchmark/${params.id}`,
        data,
    );

    return res.data;
};
