import { Menubar } from 'primereact/menubar';
import { type MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import './styles.scss';

export const SideBar = () => {
    const navigate = useNavigate();

    const items: MenuItem[] = [
        // {
        //     label: 'Скринеры',
        //     items: [
        //         {
        //             label: 'Типы инструментов',
        //             command: () => navigate('/instrument-types'),
        //         },
        //         {
        //             label: 'Группы фильтров',
        //             command: () => navigate('/filter-groups'),
        //         },
        //         {
        //             label: 'Фильтры',
        //             command: () => navigate('/filters'),
        //         },
        //         {
        //             label: 'Группы колонок',
        //             command: () => navigate('/columns-groups'),
        //         },
        //         {
        //             label: 'Колонки',
        //             command: () => navigate('/columns'),
        //         },
        //     ],
        // },
        {
            label: 'Бенчмарки',
            command: () => navigate('/benchmarks'),
        },
        {
            label: 'Стресс тесты',
            command: () => navigate('/stress-test-scenarios'),
        },
        {
            label: 'Отчеты',
            command: () => navigate('/reports'),
        },
        {
            label: 'Бенчмарки',
            command: () => navigate('/benchmarks'),
        },
        {
            label: 'Стресс тесты',
            command: () => navigate('/stress-test-scenarios'),
        },
        {
            label: 'Отчеты',
            command: () => navigate('/reports'),
        },
    ];

    return <Menubar model={items} className={`${styles.sidebar} admin-sidebar`} />;
};
