import { createEndpoint, createEndpointMap } from '../../core/endpoint';
import { deleteReportById } from './deleteReportById';
import { getReportDownloadLink } from './getReportDownloadLink';
import { getReportsList } from './getReportsList';

export const reportsEndpoints = createEndpointMap((client) => ({
    getReportsList: createEndpoint(getReportsList, client),
    deleteReportById: createEndpoint(deleteReportById, client),
    getReportDownloadLink: createEndpoint(getReportDownloadLink, client),
}));
