import { type BenchmarkResponseData, type BenchmarkRequestData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const createBenchmark: ApiFactory<
    ApiEndpoint<BenchmarkResponseData, BenchmarkRequestData>
> = (client) => async ({ data }) => {
    const res = await client.post<BenchmarkResponseData>(
        '/moex-datashop-investor-pro-portfolios-service/api/v2/benchmark',
        data,
    );

    return res.data;
};
