import { type MantineThemeOverride, Pagination, Table, Button } from '@mantine/core';

import paginationClasses from './style/Pagination.module.css';
import buttonClasses from './style/Button.module.css';

export const theme: MantineThemeOverride = {
    fontFamily: 'Inter, sans-serif',
    components: {
        Table: Table.extend({
            styles: {
                td: {
                    background: 'transparent',
                    borderRight: '2px solid #FFFFFF',
                },
                th: {
                    background: 'transparent',
                    borderRight: '2px solid transparent',
                },
            },
        }),
        Pagination: Pagination.extend({
            classNames: paginationClasses,
        }),
        Button: Button.extend({
            classNames: buttonClasses,
            styles: {
                root: {
                    fontFamily: 'Inter',
                    borderRadius: '6px',
                },
            },
        }),
    },
};
