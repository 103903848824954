import { Button, Group, LoadingOverlay, Modal, Radio, Stack, TextInput } from '@mantine/core';
import { Form, useForm, yupResolver } from '@mantine/form';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { type BenchmarkRequestData } from '@modules/InvestorProAdminPages/entities';
import { type InferParams } from '@modules/InvestorProAdminPages/shared/router';

import { type BenchmarkFormValues, benchmarkFormSchema } from './BenchmarkForm.schema';
import { SelectAsyncPaginate } from '../../shared/SelectAsyncPaginate/SelectAsyncPaginate';

export const BenchmarkModal: FC = () => {
    const queryClient = useQueryClient();
    const api = useApi();
    const { id: benchmarkId = '' } = useParams<InferParams<'/benchmarks/:id'>>();
    const navigate = useNavigate();
    const [selectedRiskyBenchmarkName, setSelectedRiskyBenchmarkName] = useState<string>();
    const [selectedProtectiveBenchmarkName, setSelectedProtectiveBenchmarkName] = useState<string>();

    const form = useForm<BenchmarkFormValues>({
        initialValues: benchmarkFormSchema.getDefault(),
        validate: yupResolver(benchmarkFormSchema),
    });

    const handleClose = () => navigate('/benchmarks');

    const riskyBenchmarksQuery = useInfiniteQuery({
        queryKey: ['riskyBenchmarks'],
        queryFn: async ({ pageParam }) => await api.benchmark.getRiskyBenchmarkDictionary({
                params: { page: pageParam, size: 20 },
            }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            return lastPage.totalPages === lastPage.pageNumber ? null : lastPage.pageNumber + 1;
        },
        select: ({ pages }) => pages.flatMap(({ content }) => content.map(({ name, secId }) => ({ value: secId, label: name }))),
    });

    const fetchNextPageRiskyBenchmarks = () => {
        riskyBenchmarksQuery.fetchNextPage().catch((e) => console.error(e));
    };

    const protectiveBenchmarksQuery = useInfiniteQuery({
        queryKey: ['protectiveBenchmarks'],
        queryFn: async ({ pageParam }) => await api.benchmark.getProtectiveBenchmarkDictionary({
                params: { page: pageParam, size: 20 },
            }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            return lastPage.totalPages === lastPage.pageNumber ? null : lastPage.pageNumber + 1;
        },
        select: ({ pages }) => pages.flatMap(({ content }) => content.map(({ name, secId }) => ({ value: secId, label: name }))),
    });

    const fetchNextPageProtectiveBenchmarks = () => {
        protectiveBenchmarksQuery.fetchNextPage().catch((e) => console.error(e));
    };

    const benchmarksQuery = useQuery({
        queryKey: ['benchmark', benchmarkId],
        queryFn: async ({ signal }) => {
            const response = await api.benchmark.getBenchmarkById({
                signal,
                params: { id: benchmarkId },
            });

            form.setValues(response);
            onEditSetBenchmarkName(response);

            return response;
        },
        enabled: !!benchmarkId && Boolean(riskyBenchmarksQuery.data) && Boolean(protectiveBenchmarksQuery.data),
    });

    const createBenchmarkMutation = useMutation({
        mutationFn: async (data: BenchmarkRequestData) => await api.benchmark.createBenchmark({ data }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['benchmark', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: handleClose,
    });

    const editBenchmarkMutation = useMutation({
        mutationFn: async (data: BenchmarkRequestData) => await api.benchmark.editBenchmark({ data, params: { id: benchmarkId } }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['benchmark', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: handleClose,
    });

    const handleSubmit = (values: BenchmarkFormValues) => {
        if (benchmarkId) {
            editBenchmarkMutation.mutate(values);
        } else {
            createBenchmarkMutation.mutate(values);
        }
    };

    const onRiskyBenchmarkChange = (value: string | null) => {
        if (value) {
            form.setFieldValue('riskyBenchmarkIndexId', value);
            const label = riskyBenchmarksQuery.data?.find((benchmark) => benchmark.value === value)?.label;

            if (form.values.selectedBenchmark === 'RISKY') {
                form.setFieldValue('name', label ?? '');
            }

            form.setFieldValue('riskyBenchmarkName', label ?? '');
            setSelectedRiskyBenchmarkName(label);
        }
    };

    const onProtectiveBenchmarkChange = (value: string | null) => {
        if (value) {
            form.setFieldValue('protectiveBenchmarkIndexId', value);
            const label = protectiveBenchmarksQuery.data?.find((benchmark) => benchmark.value === value)?.label;

            if (form.values.selectedBenchmark === 'PROTECTIVE') {
                form.setFieldValue('name', label ?? '');
            }

            form.setFieldValue('protectiveBenchmarkName', label ?? '');
            setSelectedProtectiveBenchmarkName(label);
        }
    };

    const onVisibleBenchmarkNameChange = (value: string | null) => {
        if (value) {
            form.setFieldValue('name', value);
            form.setFieldValue('selectedBenchmark', value === form.values.riskyBenchmarkName ? 'RISKY' : 'PROTECTIVE');
        }
    };

    const onEditSetBenchmarkName = useCallback(
        ({ protectiveBenchmarkName, riskyBenchmarkName, selectedBenchmark, name }: BenchmarkRequestData) => {
            setSelectedRiskyBenchmarkName(riskyBenchmarkName);
            setSelectedProtectiveBenchmarkName(protectiveBenchmarkName);
            form.setFieldValue(
                'name',
                selectedBenchmark === 'RISKY' ? riskyBenchmarkName : protectiveBenchmarkName || name,
            );
        },
        [form],
    );

    const riskyBenchmarkSelectData = useMemo(
        () => [
            ...(benchmarksQuery.data
                ? [
                      {
                          value: benchmarksQuery.data.riskyBenchmarkIndexId,
                          label: benchmarksQuery.data.riskyBenchmarkName,
                      },
                  ]
                : []),

            ...(riskyBenchmarksQuery.data?.filter((el) => el.value !== benchmarksQuery.data?.riskyBenchmarkIndexId) ??
                []),
        ],
        [benchmarksQuery, riskyBenchmarksQuery.data],
    );

    const protectiveBenchmarkSelectData = useMemo(
        () => [
            ...(benchmarksQuery.data
                ? [
                      {
                          value: benchmarksQuery.data.protectiveBenchmarkIndexId,
                          label: benchmarksQuery.data.protectiveBenchmarkName,
                      },
                  ]
                : []),

            ...(protectiveBenchmarksQuery.data?.filter(
                (el) => el.value !== benchmarksQuery.data?.protectiveBenchmarkIndexId,
            ) ?? []),
        ],
        [benchmarksQuery, protectiveBenchmarksQuery.data],
    );

    return (
        <Modal.Root opened={true} onClose={handleClose} centered={true}>
            <Modal.Overlay blur={2} />
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title fz={22} fw={700}>
                        {benchmarksQuery.data?.name
                            ? `Редактирование бенчмарка: ${benchmarksQuery.data.name}`
                            : 'Создание нового бенчмарка'}
                    </Modal.Title>
                    <Modal.CloseButton />
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay
                        visible={
                            createBenchmarkMutation.isPending ||
                            benchmarksQuery.isLoading ||
                            riskyBenchmarksQuery.isLoading ||
                            protectiveBenchmarksQuery.isLoading
                        }
                        overlayProps={{ blur: 2 }}
                        zIndex={1000}
                    />
                    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
                        <Stack gap={16}>
                            <TextInput
                                {...form.getInputProps('description')}
                                required={true}
                                label="Короткое описание"
                            />
                            <SelectAsyncPaginate
                                {...form.getInputProps('riskyBenchmarkIndexId')}
                                onChange={onRiskyBenchmarkChange}
                                data={riskyBenchmarkSelectData}
                                fetchNextPage={fetchNextPageRiskyBenchmarks}
                                isLoading={riskyBenchmarksQuery.isLoading}
                                hasNextPage={riskyBenchmarksQuery.hasNextPage}
                                clearable={false}
                                allowDeselect={false}
                                required={true}
                                label="Растущая часть"
                            />
                            <SelectAsyncPaginate
                                {...form.getInputProps('protectiveBenchmarkIndexId')}
                                onChange={onProtectiveBenchmarkChange}
                                data={protectiveBenchmarkSelectData}
                                fetchNextPage={fetchNextPageProtectiveBenchmarks}
                                isLoading={protectiveBenchmarksQuery.isLoading}
                                hasNextPage={protectiveBenchmarksQuery.hasNextPage}
                                clearable={false}
                                allowDeselect={false}
                                required={true}
                                label="Защитная часть"
                            />

                            {!!selectedRiskyBenchmarkName && !!selectedProtectiveBenchmarkName
? (
                                <Radio.Group
                                    {...form.getInputProps('name')}
                                    label="Видимое название бенчмарка"
                                    required={true}
                                    styles={{ error: { marginTop: 5 } }}
                                    onChange={onVisibleBenchmarkNameChange}
                                >
                                    <Stack gap={8}>
                                        <Radio
                                            size="xs"
                                            value={selectedRiskyBenchmarkName}
                                            label={selectedRiskyBenchmarkName}
                                        />
                                        <Radio
                                            size="xs"
                                            value={selectedProtectiveBenchmarkName}
                                            label={selectedProtectiveBenchmarkName}
                                        />
                                    </Stack>
                                </Radio.Group>
                            )
: null}

                            <Group grow={true}>
                                <Button variant="outline" onClick={handleClose}>
                                    {'Отменить'}
                                </Button>
                                <Button type="submit">{'Сохранить'}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
