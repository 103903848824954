/* eslint-disable react/prop-types */
import { ActionIcon, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { type StressTestScenario } from '@modules/InvestorProAdminPages/entities';
import { Edit } from '@modules/InvestorProAdminPages/shared/Icon/icons/Edit';
import { Remove } from '@modules/InvestorProAdminPages/shared/Icon/icons/Remove';
import { Table } from '@modules/InvestorProAdminPages/shared/Table/Table';

import { Pagination } from '../../shared/Table/Pagination';

const columnHelper = createColumnHelper<StressTestScenario>();

type Props = {
    onStressTestScenarioRemoveClick: (stressTestScenario: StressTestScenario) => void;
};

const StressTestScenariosList: FC<Props> = ({ onStressTestScenarioRemoveClick }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const handlePatchClick = useMemo(
        () => (id: string) => {
            navigate(`/stress-test-scenarios/${id}/edit`);
        },
        [navigate],
    );

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: ['stressTestScenarios', 'list', page],
        queryFn: async ({ signal }) => await api.stressTestScenarios.getStressTestScenariosList({
                signal,
                params: {
                    page,
                    size: 20,
                },
            }),
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: 'Название',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('description', {
                header: 'Короткое описание',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('updatedAt', {
                header: 'Обновлено',
                enableSorting: false,
                size: 1000,
                cell: (props) => (
                    <Text fz="14px" lh="16px" fs="normal">
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('fromDate', {
                header: 'Дата начала',
                enableSorting: false,
                size: 1000,
                cell: (props) => (
                    <Text fz="14px" lh="16px" fs="normal">
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),

            columnHelper.accessor('toDate', {
                header: 'Дата окончания',
                enableSorting: false,
                size: 1000,
                cell: (props) => (
                    <Text fz="14px" lh="16px" fs="normal">
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('uuid', {
                id: 'edit',
                header: '',
                enableSorting: false,
                size: 1,
                meta: {
                    padding: '12px 8px',
                },
                cell: ({ cell }) => (
                    <ActionIcon onClick={() => handlePatchClick(cell.getValue())} variant="transparent" size="sm">
                        <Edit width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
            columnHelper.accessor('uuid', {
                id: 'remove',
                header: '',
                enableSorting: false,
                size: 1,
                meta: { padding: '12px 8px' },
                cell: ({ row }) => (
                    <ActionIcon
                        variant="transparent"
                        size="sm"
                        onClick={() => onStressTestScenarioRemoveClick(row.original)}
                    >
                        <Remove width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
        ],
        [handlePatchClick, onStressTestScenarioRemoveClick],
    );

    return (
        <Table
            columns={columns}
            data={data?.content || []}
            highlightOnHover={true}
            isDataLoading={isLoading || isRefetching}
            striped={true}
            footer={<Pagination onChange={setPage} total={data?.totalPages ?? 0} value={page} />}
        />
    );
};

export default StressTestScenariosList;
