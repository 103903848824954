import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, Fragment, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { ConfirmationModal } from '@modules/InvestorProAdminPages/shared/ConfirmationModal';

import { BenchmarkList } from './BenchmarksList';
import { type BenchmarkListItem } from '../../entities/benchmark';

export const BenchmarksPage: FC = () => {
    const [benchmarkIdForRemove, setBenchmarkIdForRemove] = useState<BenchmarkListItem | null>(null);
    const navigate = useNavigate();
    const api = useApi();
    const queryClient = useQueryClient();

    const handleBenchmarkCreate = () => navigate('create');

    const handleDeleteModalCLose = () => {
        setBenchmarkIdForRemove(null);
    };

    const removeBenchmarkMutation = useMutation({
        mutationFn: async (id: string) => await api.benchmark.removeBenchmark({ params: { id } }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['benchmark', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: () => handleDeleteModalCLose(),
    });

    const handleDeleteConfirm = () => {
        if (benchmarkIdForRemove) {
            removeBenchmarkMutation.mutate(benchmarkIdForRemove.uuid);
        }
    };

    return (
        <Stack w={'100dvw'} px={'30px'} pb={'30px'}>
            <Group justify="space-between" mb={20}>
                <Title order={3}>{'Список созданных бенчмарков'}</Title>
                <Button onClick={handleBenchmarkCreate}>{'Добавить новый бенчмарк'}</Button>
            </Group>
            <BenchmarkList onBenchmarkRemoveClick={setBenchmarkIdForRemove} />
            <ConfirmationModal
                onClose={handleDeleteModalCLose}
                onConfirm={handleDeleteConfirm}
                opened={!!benchmarkIdForRemove}
                title="Удаление бенчмарка"
                isLoading={removeBenchmarkMutation.isPending}
                message={
                    <Text>
                        {'Вы действительно хотите удалить бенчмарк '}
                        <b>
                            {benchmarkIdForRemove?.selectedBenchmark === 'RISKY'
                                ? benchmarkIdForRemove.riskyBenchmarkName
                                : benchmarkIdForRemove?.protectiveBenchmarkName}
                        </b>
                        {'?'}
                    </Text>
                }
            />
            <Outlet />
        </Stack>
    );
};
