import { type Pagination, type PaginationParams, type StressTestScenariosResponseData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getStressTestScenariosList: ApiFactory<
    ApiEndpoint<StressTestScenariosResponseData & Pagination, void, PaginationParams>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<StressTestScenariosResponseData & Pagination>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/scenario',
        {
            signal,
            params,
        },
    );

    return data;
};
