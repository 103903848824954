import { type StressTestScenario } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getStressTestScenarioById: ApiFactory<
    ApiEndpoint<StressTestScenario, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<StressTestScenario>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/scenario/${params.id}`,
        { signal },
    );

    return data;
};
