import { type BenchmarkResponseData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getBenchmarkById: ApiFactory<
    ApiEndpoint<BenchmarkResponseData, void, { id: string } >
> = (client) => async ({ params, signal }) => {
    const { data } = await client.get<BenchmarkResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v2/benchmark/${params.id}`,
        { signal },
    );

    return data;
};
