import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { type FC, type ReactNode } from 'react';

import { ApiProvider, createEndpoints } from '../api';

const apiClient = axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/json',
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

type Props = {
    children: ReactNode;
};

export const WithApi: FC<Props> = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <ApiProvider endpoints={createEndpoints(apiClient)}>{children}</ApiProvider>
    </QueryClientProvider>
);
