import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@modules/ErrorPage';
import { TechnicPage } from '@modules/TechnicPage';
import { PersonalAccount } from '@modules/PersonalAccount';
import { Layout } from '@libs/components';
import { SessionExpiredPage } from '@modules/SessionExpiredPage';
import { AdminLayout } from '@libs/components/layout/AdminLayout';
import { MainPage } from '@modules/MainPage';
import { InstrumentTypes } from '@modules/AdminPage/InstrumentTypes';
import { FilterGroups } from '@modules/AdminPage/FilterGroups';
import { Filters } from '@modules/AdminPage/Filters';
import { ColumnsGroups } from '@modules/AdminPage/ColumnsGroups';
import { Columns } from '@modules/AdminPage/Columns';
import { InstrumentTypeCard } from '@modules/AdminPage/InstrumentTypes/InstrumentTypeCard';
import { FilterGroupCard } from '@modules/AdminPage/FilterGroups/FilterGroupCard';
import { FilterCard } from '@modules/AdminPage/Filters/FilterCard';
import { ColumnsGroupCard } from '@modules/AdminPage/ColumnsGroups/ColumnsGroupCard';
import { ColumnsCard } from '@modules/AdminPage/Columns/ColumnsCard';
import { BenchmarkModal, BenchmarksPage } from '@modules/InvestorProAdminPages/pages/benchmarks';
import { ReportsPage } from '@modules/InvestorProAdminPages/pages/reports';
import { StressTestScenariosPage } from '@modules/InvestorProAdminPages/pages/stressTestScenarios/StressTestScenariosPage';
import { StressTestScenarioModal } from '@modules/InvestorProAdminPages/pages/stressTestScenarios/StressTestScenarioModal';
import { StressTestScenarioEditModal } from '@modules/InvestorProAdminPages/pages/stressTestScenarios/StressTestScenarioEditModal';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <MainPage />,
            },
            {
                path: '',
                element: <AdminLayout />,
                children: [
                    // {
                    //     path: 'instrument-types',
                    //     element: <InstrumentTypes />,
                    // },
                    // {
                    //     path: 'instrument-types/:id',
                    //     element: <InstrumentTypeCard />,
                    // },
                    // {
                    //     path: 'filter-groups',
                    //     element: <FilterGroups />,
                    // },
                    // {
                    //     path: 'filter-groups/:id',
                    //     element: <FilterGroupCard />,
                    // },
                    // {
                    //     path: 'filters',
                    //     element: <Filters />,
                    // },
                    // {
                    //     path: 'filters/:id',
                    //     element: <FilterCard />,
                    // },
                    // {
                    //     path: 'columns-groups',
                    //     element: <ColumnsGroups />,
                    // },
                    // {
                    //     path: 'columns-groups/:id',
                    //     element: <ColumnsGroupCard />,
                    // },
                    // {
                    //     path: 'columns',
                    //     element: <Columns />,
                    // },
                    // {
                    //     path: 'columns/:id',
                    //     element: <ColumnsCard />,
                    // },
                    {
                        path: 'benchmarks',
                        element: <BenchmarksPage />,
                        children: [
                            {
                                path: 'create',
                                element: <BenchmarkModal />,
                            },
                            {
                                path: ':id',
                                element: <BenchmarkModal />,
                            },
                        ],
                    },
                    {
                        path: 'stress-test-scenarios',
                        element: <StressTestScenariosPage />,
                        children: [
                            {
                                path: 'create',
                                element: <StressTestScenarioModal />,
                            },
                            {
                                path: ':id/edit',
                                element: <StressTestScenarioEditModal />,
                            },
                        ],
                    },
                    {
                        path: 'reports',
                        element: <ReportsPage />,
                    },
                    {
                        path: 'benchmarks',
                        element: <BenchmarksPage />,
                        children: [
                            {
                                path: 'create',
                                element: <BenchmarkModal />,
                            },
                            {
                                path: ':id',
                                element: <BenchmarkModal />,
                            },
                        ],
                    },
                    {
                        path: 'stress-test-scenarios',
                        element: <StressTestScenariosPage />,
                        children: [
                            {
                                path: 'create',
                                element: <StressTestScenarioModal />,
                            },
                            {
                                path: ':id/edit',
                                element: <StressTestScenarioEditModal />,
                            },
                        ],
                    },
                    {
                        path: 'reports',
                        element: <ReportsPage />,
                    },
                ],
            },
            {
                path: 'tech-page',
                element: <TechnicPage />,
            },
            {
                path: 'personal-account',
                element: <PersonalAccount />,
            },
            {
                path: 'session-expired',
                element: <SessionExpiredPage />,
            },
        ],
    },
]);
