import { Button, Group, LoadingOverlay, Modal, Stack, Text } from '@mantine/core';
import { type FC, type ReactNode } from 'react';

type Props = {
    isLoading?: boolean;
    message?: ReactNode;
    onClose: () => void;
    onConfirm: () => void;
    opened: boolean;
    title?: string;
};

export const ConfirmationModal: FC<Props> = ({ isLoading, message, onClose, onConfirm, opened, title }) => (
    <Modal.Root opened={opened} onClose={onClose} centered={true}>
        <Modal.Overlay blur={2} />
        <Modal.Content>
            <Modal.Header>
                <Modal.Title fz={22} fw={700}>
                    {title}
                </Modal.Title>
                <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} zIndex={1000} />
                <Stack gap={16}>
                    <Text>{message}</Text>

                    <Group grow={true}>
                        <Button variant="outline" onClick={onClose}>
                            {'Отменить'}
                        </Button>
                        <Button onClick={onConfirm}>{'Подтвердить'}</Button>
                    </Group>
                </Stack>
            </Modal.Body>
        </Modal.Content>
    </Modal.Root>
);
