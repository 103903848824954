import { createEndpoint, createEndpointMap } from '../../core/endpoint';
import { createBenchmark } from './createBenchmark';
import { editBenchmark } from './editBenchmark';
import { getBenchmarkById } from './getBenchmarkById';
import { getBenchmarkList } from './getBenchmarkList';
import { getProtectiveBenchmarkDictionary } from './getProtectiveBenchmarkDictionary';
import { getRiskyBenchmarkDictionary } from './getRiskyBenchmarkDictionary';
import { removeBenchmark } from './removeBenchmark';

export const benchmarkEndpoints = createEndpointMap((client) => ({
    createBenchmark: createEndpoint(createBenchmark, client),
    editBenchmark: createEndpoint(editBenchmark, client),
    getBenchmarkById: createEndpoint(getBenchmarkById, client),
    getBenchmarkList: createEndpoint(getBenchmarkList, client),
    getProtectiveBenchmarkDictionary: createEndpoint(getProtectiveBenchmarkDictionary, client),
    getRiskyBenchmarkDictionary: createEndpoint(getRiskyBenchmarkDictionary, client),
    removeBenchmark: createEndpoint(removeBenchmark, client),
}));
