import { Feedback, Footer, Navigation } from '@libs/components';
import { useAuthorize } from '@libs/utils';

import { DatashopTitle } from './components/DatashopTitle';
import { Access } from './components/Access';

export const MainPage = () => {
    const { isAuthorized } = useAuthorize();

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <DatashopTitle />
                    {/*{isAuthorized ? null : <Access />}*/}
                    {/* <Articles /> */}
                    <Feedback />
                </div>
            </div>
        </>
    );
};
