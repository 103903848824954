import { useContext } from 'react';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { httpClient } from '@configs/httpclient.config';
import { TPrimitiveRecord } from 'react-oauth2-code-pkce/dist/Types';
import { generateState } from '../utils';

export const useAuthorize = () => {
    const { token, tokenData, logIn, logOut } = useContext<IAuthContext>(AuthContext);

    const loginHandler = (additionalParameters?: TPrimitiveRecord) => {
        if (additionalParameters && additionalParameters.isRegistration) {
            logIn(generateState({isRegistration: true}));
        } else {
            logIn();
        }
    };

    const logoutHandler = () => {
        httpClient.defaults.headers.common['Authorization'] = '';
        logOut();
    };

    return {
        loginHandler,
        logoutHandler,
        isAuthorized: !!token,
        token,
        tokenData,
    };
};
