import { unloadScript } from '@libs/services';
import { useAuthorize } from '@libs/utils';
import { type AppDispatch, getAdminPermissionsThunk, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { httpClient } from '@configs/httpclient.config';
import { useEffect } from 'react';
import { stopLoading } from '@store/store/slices/user.slice';

import { Loader } from '../../loaders/Loader';

export const Layout = () => {
    const { isAuthorized, token } = useAuthorize();

    if (token) {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    const isCommonLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isUserDataLoading = useSelector((state: RootState) => state.user.isLoading);

    const isLoading = isCommonLoading || isUserDataLoading;
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const fetchPermissions = async () => {
            if (isAuthorized) {
                await dispatch(getAdminPermissionsThunk());
            } else {
                dispatch(stopLoading());
            }
        };

        fetchPermissions();

        return () => {
            unloadScript();
        };
    }, [isAuthorized]);

    return (
        <>
            <Outlet />
            <ScrollRestoration />
            <Loader isLoading={isLoading} />
        </>
    );
};
