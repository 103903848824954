import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Remove: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} fill={color}
viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 5.01923C3.58579 5.01923 3.25 5.35502 3.25 5.76923C3.25 6.18344 3.58579 6.51923
        4 6.51923V5.01923ZM20 6.51923C20.4142 6.51923 20.75 6.18344 20.75 5.76923C20.75 5.35502
        20.4142 5.01923 20 5.01923V6.51923ZM4 6.51923H5.45455V5.01923H4V6.51923ZM4.70455
        5.76923V20H6.20455V5.76923H4.70455ZM6.45455 21.75H17.5455V20.25H6.45455V21.75ZM18.5455
        6.51923H20V5.01923H18.5455V6.51923ZM19.2955 20V5.76923H17.7955V20H19.2955ZM5.45455
        6.51923H9.09091V5.01923H5.45455V6.51923ZM9.84091 5.76923V4H8.34091V5.76923H9.84091ZM10.0909
        3.75H13.9091V2.25H10.0909V3.75ZM9.09091 6.51923H14.9091V5.01923H9.09091V6.51923ZM14.9091
        6.51923H18.5455V5.01923H14.9091V6.51923ZM14.1591 4V5.76923H15.6591V4H14.1591ZM9.06818
        9.23077V17.5385H10.5682V9.23077H9.06818ZM14.9318 17.5385V9.23077H13.4318V17.5385H14.9318ZM9.84091
        4C9.84091 3.86193 9.95284 3.75 10.0909 3.75V2.25C9.12441 2.25 8.34091 3.0335 8.34091
        4H9.84091ZM17.5455 21.75C18.512 21.75 19.2955 20.9665 19.2955 20H17.7955C17.7955
        20.1381 17.6835 20.25 17.5455 20.25V21.75ZM13.9091 3.75C14.0472 3.75 14.1591
        3.86193 14.1591 4H15.6591C15.6591 3.0335 14.8756 2.25 13.9091 2.25V3.75ZM4.70455
        20C4.70455 20.9665 5.48805 21.75 6.45455 21.75V20.25C6.31647 20.25 6.20455 20.1381 6.20455 20H4.70455Z"
        />
    </svg>
);
