import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getReportDownloadLink: ApiFactory<
    ApiEndpoint<{ downloadUrl: string }, void, { id: string | null }>
> = (client) => async ({ signal, params: { id } }) => {
    const { data } = await client.get<{ downloadUrl: string }>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/reports/download/${id}`,
        { signal },
    );

    return data;
};
