import { type CSSVariablesResolver, MantineProvider } from '@mantine/core';
import { type FC, type ReactNode } from 'react';

import { theme } from '../config';

type Props = {
    children: ReactNode;
};

const resolver: CSSVariablesResolver = () => ({
    variables: {
        '--table-striped-color': '#f5f8fd',
        '--table-highlight-on-hover-color': '#e7f0f9',
    },
    light: {
        '--table-striped-color': '#f5f8fd',
        '--table-highlight-on-hover-color': '#e7f0f9',
    },
    dark: {
        '--table-striped-color': '#f5f8fd',
        '--table-highlight-on-hover-color': '#e7f0f9',
    },
});

export const WithMantine: FC<Props> = ({ children }) => (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
        {children}
    </MantineProvider>
);
