import { Table } from '@mantine/core';
import { type Row } from '@tanstack/react-table';

import { TableCell } from './TableCell';

type Props<T extends Record<string, unknown>> = {
    row: Row<T>;
    selected?: boolean;
};

export const TableRow = <TableData extends Record<string, unknown>>({ row }: Props<TableData>) => (
    <Table.Tr>
        {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id} cell={cell} />
        ))}
    </Table.Tr>
);
