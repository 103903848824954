import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const deleteReportById: ApiFactory<
    ApiEndpoint<void, void, { id: string }>
> = (client) => async ({ params }) => {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    const res = await client.delete<void>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/reports/delete?id=${params.id}`,
    );

    return res.data;
};
