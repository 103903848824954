import { createEndpointMap } from '../core/endpoint';
import { benchmarkEndpoints } from './benchmark';
import { reportsEndpoints } from './reports';
import { stressTestScenariosEndpoints } from './stressTestScenarios';

export const createEndpoints = createEndpointMap((client) => ({
    benchmark: benchmarkEndpoints(client),
    stressTestScenarios: stressTestScenariosEndpoints(client),
    reports: reportsEndpoints(client),
}));

export type ApiEndpoints = ReturnType<typeof createEndpoints>;
