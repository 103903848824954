import * as yup from 'yup';

export const stressTestScenarioForm = yup.object({
    name: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default('')
        .trim(),
    description: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default('')
        .trim(),
    fromDate: yup
        .date()
        .required('Поле обязательно'),
    toDate: yup
        .date()
        .required('Поле обязательно'),
});

export type StressTestScenarioFormValues = yup.Asserts<typeof stressTestScenarioForm>;
