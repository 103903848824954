import { type StressTestScenario, type StressTestScenarioRequestData } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const editStressTestScenario: ApiFactory<
    ApiEndpoint<StressTestScenario, StressTestScenarioRequestData, { id: string }>
> = (client) => async ({ data, params }) => {
    const res = await client.put<StressTestScenario>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/scenario/${params.id}`,
        data,
    );

    return res.data;
};
