import { type BenchmarkListResponseData, type Pagination, type PaginationParams } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getBenchmarkList: ApiFactory<
    ApiEndpoint<BenchmarkListResponseData & Pagination, void, PaginationParams>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<BenchmarkListResponseData & Pagination>(
        '/moex-datashop-investor-pro-portfolios-service/api/v2/benchmark',
        {
            signal,
            params,
        },
    );

    return data;
};
