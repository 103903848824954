import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, Fragment, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { type StressTestScenario } from '@modules/InvestorProAdminPages/entities';
import { ConfirmationModal } from '@modules/InvestorProAdminPages/shared/ConfirmationModal';

import StressTestScenariosList from './StressTestScenariosList';

export const StressTestScenariosPage: FC = () => {
    const navigate = useNavigate();
    const api = useApi();
    const queryClient = useQueryClient();
    const [stressTestScenarioIdForRemove, setStressTestScenarioIdForRemove] = useState<StressTestScenario | null>(null);
    const handleDeleteModalCLose = () => {
        setStressTestScenarioIdForRemove(null);
    };

    const deleteStressTestScenarioMutation = useMutation({
        mutationFn: async (id: string) => await api.stressTestScenarios.deleteStressTestScenarioById({ params: { id } }),
        onSuccess: async () => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['stressTestScenarios', 'list'] });
            } catch (e) {
                console.error(e);
            }
        },
        onSettled: () => handleDeleteModalCLose(),
    });

    const handleBenchmarkCreate = () => navigate('create');

    const handleDeleteConfirm = () => {
        if (stressTestScenarioIdForRemove) {
            deleteStressTestScenarioMutation.mutate(stressTestScenarioIdForRemove.uuid);
        }
    };

    return (
        <Stack w={'100dvw'} px={'30px'} pb={'30px'}>
            <Group justify="space-between" mb={20}>
                <Title order={3}>{'Список созданных сценариев стресс тестирования'}</Title>
                <Button onClick={handleBenchmarkCreate}>{'Добавить сценарий стресс тестирования'}</Button>
            </Group>
            <StressTestScenariosList onStressTestScenarioRemoveClick={setStressTestScenarioIdForRemove} />
            <ConfirmationModal
                onClose={handleDeleteModalCLose}
                onConfirm={handleDeleteConfirm}
                opened={!!stressTestScenarioIdForRemove}
                title="Удаление сценария стресс тестирования"
                isLoading={deleteStressTestScenarioMutation.isPending}
                message={
                    <Text>
                        {'Вы действительно хотите удалить сценарий стресс тестирования '}
                        <b>{stressTestScenarioIdForRemove?.name}</b>
                        {'?'}
                    </Text>
                }
            />
            <Outlet />
        </Stack>
    );
};
