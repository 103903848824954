import * as yup from 'yup';

export const benchmarkFormSchema = yup.object({
    description: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default('')
        .trim(),
    riskyBenchmarkIndexId: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default(''),
    protectiveBenchmarkIndexId: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default(''),
    name: yup
        .string()
        .required('Поле обязательно для заполнения')
        .default(''),
    selectedBenchmark: yup
        .mixed<'RISKY' | 'PROTECTIVE'>().oneOf(['RISKY' , 'PROTECTIVE'])
        .default('RISKY'),
    protectiveBenchmarkName: yup
        .string()
        .default(''),
    riskyBenchmarkName: yup
        .string()
        .default(''),
});

export type BenchmarkFormValues = yup.InferType<typeof benchmarkFormSchema>;
