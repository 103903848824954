import { Group, Table, Text } from '@mantine/core';
import { type Header, flexRender } from '@tanstack/react-table';

import { SortButton } from './SortButton';

type Props<T extends Record<string, unknown>> = {
    header: Header<T, unknown>;
};

export const TableHeaderCell = <TableData extends Record<string, unknown>>({ header }: Props<TableData>) => {
    const justifyContent = header.column.columnDef.meta?.justifyContent ?? 'space-between';

    return (
        <Table.Th style={{ width: header.getSize(), padding: '0 0 13px 0' }}>
            <Group
gap="6px" wrap="nowrap" pl="18px"
style={{ whiteSpace: 'nowrap', justifyContent }}>
                <Text style={{ color: '#8A96A6', fontSize: '14px', lineHeight: '16px' }}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                </Text>
                {header.column?.getCanSort()
? (
                    <SortButton
                        toggleSort={header.column.getToggleSortingHandler}
                        sortDirection={header.column.getIsSorted()}
                    />
                )
: null}
            </Group>
        </Table.Th>
    );
};
