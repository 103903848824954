import React from 'react';
import ReactDOM from 'react-dom/client';
import utcPlugin from 'dayjs/plugin/utc';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import './index.scss';
import { ThemeProvider } from '@libs/themes';
import ConfigProvider from '@dynamic-env/useConfig';
import { Providers } from '@modules/InvestorProAdminPages/providers';
import { ConfiguredApp } from '@dynamic-env/configured-app';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './configs/i18n';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <ConfigProvider>
            <ThemeProvider>
                <ConfiguredApp>
                    <Providers>
                        <App />
                    </Providers>
                </ConfiguredApp>
            </ThemeProvider>
        </ConfigProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
