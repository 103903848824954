import { Outlet } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { useAuthorize } from '@libs/utils';
import { Navigation } from '@libs/components/pageBlocks/Navigation';

import { SideBar } from './Sidebar';

export const AdminLayout = () => {
    const { isAuthorized } = useAuthorize();

    return (
        <>
            <Navigation />
            <div className={classNames('flex')}>
                {isAuthorized && (
                    <>
                        <SideBar />
                        <Outlet />
                    </>
                )}
            </div>
        </>
    );
};
