import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as LogOutIcon } from '@shared/images/svg/LogOutIcon.svg';
import { Avatar } from '@libs/components';
import { Link } from 'react-router-dom';
import { type AppDispatch, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

const linkRoutes = {
    instrumentTypes: '/instrument-types',
    account: '/personal-account',
    cart: '/cart',
    products: '/products',
    // articles: '/articles',
    // partners: '#',
    // help: '/help-center',
    // support: '#',
    logout: '#',
    empty: '#',
};

export const BurgerMenu = ({ loginHandler, logoutHandler, isAuthorized }: any) => {
    const dispatch = useDispatch<AppDispatch>();

    const logoutWithUnsub = () => {
        logoutHandler();
    };

    return (
        <div className={styles.menuWrapper}>
            <div className={styles.backgroundFilter} />
            <div className={classNames(styles.navigationButtons, styles.buttonsInMenu)}>
                {/*{!isAuthorized ? (*/}
                {/*    <>*/}
                {/*        <Button*/}
                {/*            className={styles.whiteBtn}*/}
                {/*            label={'Регистрация'}*/}
                {/*            onClick={() => loginHandler({ isRegistration: true })}*/}
                {/*        />*/}
                {/*        <Button className={styles.redBtn} label={'Вход'} onClick={() => loginHandler()} />*/}
                {/*    </>*/}
                {/*) : (*/}
                {/*    <>*/}
                {/*        <ul className={classNames('flex', 'flex-column', styles.userFunctionsListWrapper)}>*/}
                {/*            /!* todo: removed from june release *!/*/}
                {/*            /!*<Link*!/*/}
                {/*            /!*    to={linkRoutes.account}*!/*/}
                {/*            /!*    className={classNames('flex', 'justify-content-between', 'align-items-center')}*!/*/}
                {/*            /!*>*!/*/}
                {/*            /!*    <p>Личный кабинет</p>*!/*/}
                {/*            /!*</Link>*!/*/}

                {/*            /!* todo: removed from april release *!/*/}
                {/*            /!* <Link*/}
                {/*                to={linkRoutes.cart}*/}
                {/*                className={classNames('flex', 'justify-content-between', 'align-items-center')}*/}
                {/*            >*/}
                {/*                <p>Корзина</p>*/}
                {/*                {cartCount && <span className={styles.cartCount}>{cartCount}</span>}*/}
                {/*            </Link> *!/*/}
                {/*        </ul>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
            {/*<hr />*/}
            <ul className={classNames('flex', 'flex-column')}>
                {/*<li>*/}
                {/*    <Link to={linkRoutes.instrumentTypes}>Управление</Link>*/}
                {/*</li>*/}
                {/* <li> */}
                {/*    <Link to={linkRoutes.articles}>Статьи</Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*    <Link to={linkRoutes.partners}>Партнерам</Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*    <Link to={linkRoutes.help}>Справочный центр</Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*    <Link to={linkRoutes.support}>Написать в поддержку</Link> */}
                {/* </li> */}
                {isAuthorized
? (
                    <>
                        <li className={styles.logout}>
                            <LogOutIcon />
                            <Link to={linkRoutes.logout} onClick={logoutWithUnsub}>
                                Выйти
                            </Link>
                        </li>
                    </>
                )
: null}
            </ul>
        </div>
    );
};
