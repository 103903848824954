import { ActionIcon } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { type FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '@modules/InvestorProAdminPages/api';
import { type BenchmarkListItem } from '@modules/InvestorProAdminPages/entities';
import { Table } from '@modules/InvestorProAdminPages/shared/Table';
import { Edit } from '@modules/InvestorProAdminPages/shared/Icon/icons/Edit';

import { Remove } from '../../shared/Icon/icons/Remove';
import { Pagination } from '../../shared/Table/Pagination';

const columnHelper = createColumnHelper<BenchmarkListItem>();

type Props = {
    onBenchmarkRemoveClick: (benchmark: BenchmarkListItem) => void;
};

export const BenchmarkList: FC<Props> = ({ onBenchmarkRemoveClick }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: ['benchmark', 'list', page],
        queryFn: async ({ signal }) => await api.benchmark.getBenchmarkList({
                params: {
                    page,
                    size: 20,
                },
                signal,
            }),
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor('description', {
                header: 'Короткое описание',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('riskyBenchmarkName', {
                header: 'Растущая часть',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('protectiveBenchmarkName', {
                header: 'Защитная часть',
                enableSorting: false,
                size: 1000,
            }),
            columnHelper.accessor('uuid', {
                id: 'edit',
                header: '',
                enableSorting: false,
                size: 1,
                meta: {
                    padding: '12px 8px',
                },
                cell: ({ cell }) => (
                    <ActionIcon variant="transparent" size="sm" onClick={() => navigate(cell.getValue())}>
                        <Edit width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
            columnHelper.accessor('uuid', {
                id: 'remove',
                header: '',
                enableSorting: false,
                size: 1,
                meta: {
                    padding: '12px 8px',
                },
                cell: ({ row }) => (
                    <ActionIcon variant="transparent" size="sm" onClick={() => onBenchmarkRemoveClick(row.original)}>
                        <Remove width={20} height={20} color="#A5B1C0" />
                    </ActionIcon>
                ),
            }),
        ],
        [navigate, onBenchmarkRemoveClick],
    );

    return (
        <Table
            columns={columns}
            data={data?.content || []}
            highlightOnHover={true}
            isDataLoading={isLoading || isRefetching}
            striped={true}
            footer={<Pagination onChange={setPage} total={data?.totalPages ?? 0} value={page} />}
        />
    );
};
