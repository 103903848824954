import { type DictionaryBenchmarkList, type Pagination } from '@modules/InvestorProAdminPages/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getRiskyBenchmarkDictionary: ApiFactory<
    ApiEndpoint<DictionaryBenchmarkList & Pagination, void, { page: number; size: number }>
> = (client) => async ({ params, signal }) => {
    const { data } = await client.get<DictionaryBenchmarkList & Pagination>(
        '/moex-datashop-investor-pro-portfolios-service/api/v2/benchmark/risky',
        { signal, params },
    );

    return data;
};
