import { createEndpoint, createEndpointMap } from '../../core/endpoint';
import { createStressTestScenario } from './createStressTestScenario';
import { deleteStressTestScenarioById } from './deleteStressTestScenarioById';
import { editStressTestScenario } from './editStressTestScenario';
import { getStressTestScenarioById } from './getStressTestScenarioById';
import { getStressTestScenariosList } from './getStressTestScenariosList';

export const stressTestScenariosEndpoints = createEndpointMap((client) => ({
    getStressTestScenariosList: createEndpoint(getStressTestScenariosList, client),
    createStressTestScenario: createEndpoint(createStressTestScenario, client),
    deleteStressTestScenarioById: createEndpoint(deleteStressTestScenarioById, client),
    editStressTestScenario: createEndpoint(editStressTestScenario, client),
    getStressTestScenarioById: createEndpoint(getStressTestScenarioById, client),
}));
