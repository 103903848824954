import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuthorize } from '@libs/utils';
import { type AppDispatch, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogOutIcon } from '@shared/images/svg/LogOutIcon.svg';

import styles from './styles.module.scss';

const linkRoutes = {
    account: '/personal-account',
    cart: '/cart',
};

export const DropdownUserPanel = () => {
    const { logoutHandler } = useAuthorize();
    const dispatch = useDispatch<AppDispatch>();

    const logoutWithUnsub = () => {
        logoutHandler();
    };

    const liClassString = ['flex', 'align-items-center'];

    return (
        <ul className={styles.functionList}>
            {/* todo: removed from june release */}
            {/*<li className={classNames(liClassString)}>*/}
            {/*    <Link to={linkRoutes.account}>*/}
            {/*        <p>Личный кабинет</p>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Уведомления</p> */}
            {/* </li> */}

            {/* todo: removed from april release */}
            {/* <li className={classNames(liClassString)}>
                <Link to={linkRoutes.cart} className={classNames(liClassString)}>
                    <p>Корзина</p>
                    {cartCount && <span className={styles.cartCount}>{cartCount}</span>}
                </Link>
            </li> */}

            {/*<div />*/}
            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Справка</p> */}
            {/* </li> */}
            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Написать в поддержку</p> */}
            {/* </li> */}
            <li onClick={logoutWithUnsub} className={classNames(liClassString, styles.logoutWrapper)}>
                <LogOutIcon />
                <p>Выйти</p>
            </li>
        </ul>
    );
};
